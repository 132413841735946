// Import the `createSlice` function from `@reduxjs/toolkit`
import { createSlice } from "@reduxjs/toolkit";

// Define the initial state for the loading slice
const initialState = {
  isLoading: false, // Indicates if the application is currently in a loading state
  selectedSideNavOption: {
    id: undefined,
    menu_name: "Please Select Folder From Side Menu!",
    menu_icon: "assets/img/avatar/default-user.svg",
  }, //Stores the folder option selected by user
  selectedCategoryOption: null,
  selectedYearOption: 2024,
  uploadedDocumentInfo: [],
  uploadedGeneralComment: [],
};

// Create the loading slice using `createSlice`
const loadingSlice = createSlice({
  name: "common", // Name of the slice
  initialState, // Initial state defined above
  reducers: {
    // Reducer function for toggling the loading state
    toggleLoading: (state, actions) => {
      console.log(state.isLoading, "loading");
      state.isLoading = actions?.payload ? actions.payload : !state.isLoading; // Toggle the loading state based on the payload of the action or invert the current loading state
    },
    SideNavChoice: (state, actions) => {
      console.log(state.selectedSideNavOption, "selectedSideNavOption");
      state.selectedSideNavOption = actions?.payload
        ? actions.payload
        : !state.selectedSideNavOption; // Toggle the loading state based on the payload of the action or invert the current loading state
    },
    CategoryChoice: (state, actions) => {
      console.log(state.selectedCategoryOption, "selectedCategoryOption");
      state.selectedCategoryOption = actions?.payload
        ? actions.payload
        : !state.selectedCategoryOption; // Toggle the loading state based on the payload of the action or invert the current loading state
    },
    YearChoice: (state, actions) => {
      console.log(state.selectedYearOption, "selectedYearOption");
      state.selectedYearOption = actions?.payload
        ? actions.payload
        : !state.selectedYearOption; // Toggle the loading state based on the payload of the action or invert the current loading state
    },
    UploadedDocumentInfo: (state, actions) => {
      const { payload } = actions;
      console.log(
        payload,
        "payload ssssssssss",
        state.uploadedDocumentInfo && payload !== null
      );
      state.uploadedDocumentInfo =
        state.uploadedDocumentInfo && payload !== null ? payload : payload; // Merge the user details with the existing state or set it as the new details
    },
    // Reducer function for removing a document from uploadedDocumentInfo
    removeDocument: (state, action) => {
      let idsToRemove = [];
      console.log(action.payload, "payloadddddd");
      if (typeof action.payload.ids === "string") {
        // If it's a single ID as a string, convert it to an array
        idsToRemove = [action.payload.ids];
      } else {
        // If it's already an array, use it directly
        idsToRemove = action.payload.ids;
      }

      console.log(idsToRemove, "IDs to be removed");

      // Use filter to exclude documents with matching IDs
      state.uploadedDocumentInfo = state.uploadedDocumentInfo.filter(
        (document) => !idsToRemove.includes(document.id)
      );
    },
    UploadGeneralComment: (state, actions) => {
      console.log(actions?.payload, "payload ssssssssss", state);
      const { payload } = actions;
      state.uploadedGeneralComment =
        state.uploadedGeneralComment && payload !== null ? payload : payload;
    },
        // Reducer function for removing a comments from uploadedDocumentInfo
        removeComments: (state, action) => {
          let idsToRemove = [];
          console.log(action.payload, "payloadddddd");
          if (typeof action.payload.commentIds === "string") {
            // If it's a single ID as a string, convert it to an array
            idsToRemove = [action.payload.commentIds];
          } else {
            // If it's already an array, use it directly
            idsToRemove = action.payload.commentIds;
          }
    
          console.log(idsToRemove, "IDs to be removed");
    
          // Use filter to exclude documents with matching IDs
          state.uploadedGeneralComment = state.uploadedGeneralComment?.filter(
            (comment) => !idsToRemove.includes(comment.id)
          );
        },
  },
});

// Extract the action creators generated by `createSlice`
export const {
  toggleLoading,
  SideNavChoice,
  CategoryChoice,
  YearChoice,
  removeDocument,
  UploadedDocumentInfo,
  UploadGeneralComment,
  removeComments
} = loadingSlice.actions;

// Export the reducer function generated by `createSlice`
export default loadingSlice.reducer;
