// export const API_END_POINT = {
//   login: `/login/appauth.php`,
//   getSidebatMenuList: `/functions.php?action=get-menu`,
//   // getCategoryMenuList:`/functions.php?action=get-category`,
//    uploadDocument: `/appuploads/appsave.php`,
//   // uploadDocument:`/functions.php?action=post-document`,
//   getAllDocumentCommentList: `/functions.php?action=get-document-list-by-category-id&`,
//   addGeneralComments: `/functions.php?action=post-comment`,
//   getAllGeneralComments: `/functions.php?action=get-comments`,
//   deleteDocument: `/functions.php?action=delete-document`,
//   deleteComment: `/functions.php?action=delete-comment`,
//   logout: `/functions.php?action=logout`,
// };

export const API_END_POINT = {
  login: `/login/appauth.php`,
  getSidebatMenuList: `/functions.php?action=get-menu`,
  uploadDocument: `/appuploads/appsave.php`,
  getAllDocumentCommentList: `/functions.php?action=get-document-list-by-category-id&`,
  addGeneralComments: `/functions.php?action=post-comment`,
  getAllGeneralComments: `/functions.php?action=get-comments`,
  deleteDocument: `/functions.php?action=delete-document`,
  deleteComment: `/functions.php?action=delete-comment`,
  logout: `/functions.php?action=logout`,
};
