import toast from "react-hot-toast";

/**
 * @method apiErrorHandler
 * @description handle api error
 */
// export const apiErrorHandler = (res) => {
//   const response = res ? res.response : undefined;

//   if (response && response.data && response.status === 401) {
//     if (response && response.data && response.data.error) {
//       toast.error("You are not authorized", {
//         id: "unauthorize",
//       });
//       store.dispatch(logoutUser());
//       localStorage.clear();
//       sessionStorage.clear();
//       window.location.assign("/");
//     } else {
//       toast.error(response.data.Message, { id: "unauthorize" });
//     }
//   } else if (response && response.status === 400) {
//     // toast.error(response.data.Message, {
//     //   id: "err_400",
//     // });
//   } else if (response && response.status === 409) {
//     toast.error(response.data && response.data.Message, {
//       id: "err_409",
//     });
//   } else if (response && response.status >= 500) {
//     toast.error("Something went wrong please try again later", {
//       id: "some-went-wrong",
//     });
//   }
// };
export const apiErrorHandler = (res) => {
  console.log('response^^^apiErrorHandler', res)
  if (res && res?.response?.data && res?.response?.data?.message) {
    toast.error(res?.response?.data?.message)
  } else {
    toast.error('Something went wrong please try again later')
  }
};
