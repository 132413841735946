import React, { useEffect, useState } from "react";
import CommonFormikForm from "./CommonFormikForm";
import { login } from "../../../services/auth-service";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { loginValidationSchema } from "../../../utils/validations/authValidations";
import toast from 'react-hot-toast';
import { loginSuccess, saveCredentials } from "../../../store/slices/auth-slice";

const LoginForm = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [rememberMe, setRememberMe] = useState(true); // State to track Remember Me checkbox
  // const { toggleLoader } = useLoader();
  const previousCredentials = useSelector((state) => (state?.auth?.saveCredentials))

  const initialValues = {
    Email: previousCredentials && previousCredentials?.email || '', // Load saved email if Remember Me is checked
    Pin: previousCredentials && previousCredentials?.pin || '' // Load saved pin if Remember Me is checked
  };

  const handleSubmit = async (values) => {
    console.log(values)

    try {
      let reqData = {
        email: values?.Email,
        pin: values?.Pin
      };
      const res = await login(reqData);
console.log(res ,"resresres")
      if (res.data && res?.status == 200) {
        navigate('/user-dashboard');
        const user_details = res?.data
        dispatch(loginSuccess(user_details));
        localStorage.setItem('token', res?.data?.token);

        // Save email and pin if Remember Me is checked
        if (rememberMe) {
          const credentials = {
            email: values?.Email,
            pin: values?.Pin
          }
          dispatch(saveCredentials(credentials))
        } else if (!rememberMe) {
          dispatch(saveCredentials(null))
        }
      } else {
        let message = res.data && res.data.message;
        toast.error(message);
      }
    } catch (error) {
      console.log(error, "errerrerr");
      toast.error(error.response.data.message);
    } finally {
      // toggleLoader(false);
    }
  }
  const handleCheckboxChange = (event) => {
    console.log(event, "eventeventevent")
    const isChecked = event.target.checked;
    console.log(isChecked, "isChecked")
    setRememberMe(isChecked)
    if (!isChecked) {
      dispatch(saveCredentials(null))

    }
  };
  const renderInputs = [
    {
      type: 'text',
      name: 'Email',
      placeholder: 'USERNAME OR EMAIL',
    },
    {
      type: 'number',
      name: 'Pin',
      placeholder: 'PIN',
      inputType:'number', // Change input type to password for Pin
    },
    // Checkbox for Remember Me
    {
      type: 'checkbox',
      name: 'RememberMe',
      title: 'Save Login Credentials',
      checked: rememberMe,
      onChange: handleCheckboxChange, // Toggle Remember Me state
    }
  ];

  return (
    <>
      <section className="login-page">
        <div className="login-page-header py-5 px-3">
          <div className="brand-logo">
            <img src="assets/img/logo-white.jpg" alt="logo" className="logo" />
          </div>
        </div>

        <div className="login-page-body">
          <div className="card mx-3 mx-md-5 shadow-5-strong">
            <div className="card-body py-4 py-md-5 px-4 px-md-5">
              <CommonFormikForm
                initialValues={initialValues}
                validationSchema={loginValidationSchema}
                onSubmit={handleSubmit}
                renderInputs={renderInputs}
                ButtonText={'SIGN IN'}
                submitBtnClass={'w-100'}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default LoginForm
