import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid'; 
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import SideBarLayout from './SideBarLayout';
import AppModal from '../modals';
import CommonFormikForm from '../common/form/CommonFormikForm';
import { Button } from 'react-bootstrap';
import { addGeneralCommentValidationSchema } from '../../utils/validations/common-validations';
import { useLocation, useNavigate } from 'react-router-dom';
import { uploadComment } from '../../services/add-service';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlinePlusCircle } from "react-icons/ai";
import { UploadGeneralComment } from '../../store/slices/common-slice';

function NavBar() {
    const [showCommentModal, setShowCommentModal] = useState(false)
    const { pathname } = useLocation();
    const userDetails = useSelector((state) => (state?.auth?.userDetails));
    const selectedSideNavOption = useSelector((state) => state?.common?.selectedSideNavOption)
    const selectedCategoryOption = useSelector((state) => state?.common?.selectedCategoryOption)
    const retrievedArray = useSelector((state) => state?.common?.uploadedGeneralComment);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const initialValues = {
        GeneralComments: ''
    }

    const handleSubmit = async (values) => {
        console.log(values, "values")
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const uniqueId = uuidv4(); // Generate a unique ID
        const newComment = {
            id: uniqueId,
            userId: userDetails && userDetails?.userId,
            selectedSideNavOption: selectedSideNavOption && selectedSideNavOption?.staffid,
            comment: values?.GeneralComments,
            year: currentYear,
        }
        if (values?.GeneralComments) {
            const mergedArray = retrievedArray ? [...retrievedArray, newComment] : [newComment];
            dispatch(UploadGeneralComment(mergedArray));
            toast?.success("General Comment Added Successfully!");
            // navigate("/view-document");
            setShowCommentModal(false);
        }
    }
    const renderInputs = [
        {
            type: 'textarea',
            name: 'GeneralComments',
            placeholder: 'Comment',
            inputType: 'textarea',
        },
    ];
    return (
        <>
            <Navbar data-bs-theme="light" className="header-navbar">
                <Container>
                    <Navbar.Brand><SideBarLayout /></Navbar.Brand>
                    <Nav.Link href="/user-dashboard" className="justify-content-center small-logo"><img src="assets/img/logo-icon.png" alt="Company Logo" /></Nav.Link>
                    <Nav className="justify-content-end">
                        {pathname === "/user-dashboard" &&
                            <Nav.Link onClick={() => setShowCommentModal(true)} className="add-general-comment"><AiOutlinePlusCircle /> <span>Add General Comment</span></Nav.Link>
                        }
                    </Nav>
                </Container>
            </Navbar>
            <AppModal
                show={showCommentModal}
                onClose={() => setShowCommentModal(false)}
                bodyClass="p-4"
                contentClassName={'bg-light'}
                size={'md'}
                heading='Add General Comments'
            >
                <CommonFormikForm
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    renderInputs={renderInputs}
                    validationSchema={addGeneralCommentValidationSchema}
                    isCancelBtnVisible={true}
                    onCancel={() => setShowCommentModal(false)}
                    footerClass="d-flex justify-content-end gap-2"
                />
            </AppModal>
        </>
    );
}

export default NavBar;