import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import commonSlice from "./common-slice";

const initialState = {
  isLoggedIn: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      const { payload } = action;
      state.isLoggedIn = !!payload;
      state.userDetails = state.userDetails
        ? { ...state.userDetails, ...payload }
        : payload; // Merge the user details with the existing state or set it as the new details
    },
    // Reducer function for handling logout
    logoutUser: (state, action) => {
      console.log(state.common, "state", action);
      state.isLoggedIn = false; // Set isLoggedIn to false
      state.userDetails = null; // Reset user details to null
    },
    saveCredentials: (state, action) => {
      const { payload } = action;
      state.saveCredentials = payload // Merge the user details with the existing state or set it as the new details
    },
  },
});
// Extract the action creators generated by `createSlice`
export const { loginSuccess, logoutUser, saveCredentials } = authSlice.actions;
// Export the reducer function generated by `createSlice`
export default authSlice.reducer;
