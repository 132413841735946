export const VALIDATION_MSG = {
  required: {
    email: "Please enter a valid email id.",
    pin: "Please enter a valid Pin number.",
  },
  valid: {
    pin: "Please enter a valid 4-digit pin number.",
    email: "Please enter a valid email id.",
  },
};

export const MESSAGES = {
  LOGIN_SUCCESS: "Login Successfully !",
};
