import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid'; // Import the uuid library to generate unique IDs
import ProfileImage from "../components/common/ProfileImage";
import { Link, useNavigate } from "react-router-dom";
import CommonFormikForm from "../components/common/form/CommonFormikForm";
import { useDispatch, useSelector } from "react-redux";
import { uploadDocument } from "../services/add-service";
import toast from "react-hot-toast";
import { fileUploadValidationSchema } from "../utils/validations/common-validations";
import { UploadedDocumentInfo, YearChoice } from "../store/slices/common-slice";
import {
    CircularProgressbar, buildStyles
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import ChangingProgressProvider from "../components/loader/ChangingProgressProvider";
import { Container } from "react-bootstrap";
import { FaChevronRight } from "react-icons/fa";

const UploadDocument = () => {
    const navigate = useNavigate()
    const [route, setRoute] = useState("upload-document")
    const [loading, setLoading] = useState(false);
    const [uploadInfoArray, setUploadInfoArray] = useState([])
    console.log(uploadInfoArray, "uploadInfoArray12")
    const previousUploadDocInfo = localStorage.getItem("uploadDocumentInfo")
    console.log(previousUploadDocInfo, "previousUploadDocInfo")
    const retrievedArray = useSelector((state) => state?.common?.uploadedDocumentInfo);
    const userDetails = useSelector((state) => (state?.auth?.userDetails));

    console.log(userDetails, "userDetaffffils")
    const selectedSideNavOption = useSelector((state) => state?.common?.selectedSideNavOption);
    const selectedCategoryOption = useSelector((state) => state?.common?.selectedCategoryOption);
    const selectedYearOption = useSelector((state) => state?.common?.selectedYearOption);
    console.log(retrievedArray, "retrievedArray")
    const dispatch = useDispatch();

    const initialValues = {
        Document: [],
        Comment: ""
    };



    const handleSubmit = async (values, { setFieldValue, resetForm }) => {
        console.log(values, "values?.Document")
        try {
            setLoading(true);
            const uniqueId = uuidv4(); // Generate a unique ID
            const currentDate = new Date();
            const currentYear = currentDate.getFullYear();
            dispatch(YearChoice(currentYear));
            // const { name, lastModified, lastModifiedDate, webkitRelativePath, size, type } = values?.Document
            // const serializedLastModifiedDate = lastModifiedDate.getTime();
            const newDocument = {
                userId: userDetails && userDetails?.userId,
                selectedSideNavOption: selectedSideNavOption && selectedSideNavOption?.staffid,
                selectedCategoryOption: selectedCategoryOption && selectedCategoryOption?.id,
                selectedCategoryName: selectedCategoryOption && selectedCategoryOption?.category_name,
                selectedYearOption: currentYear,
                id: uniqueId,
                comments: values?.Comment,
                document: values.Document
            }
            if (!retrievedArray && values?.Comment && values?.Document) {
                const mergedTheArray = [newDocument]
                dispatch(UploadedDocumentInfo(mergedTheArray))
                navigate("/view-document")
                console.log(uploadInfoArray, "uploadInfoArray")
            } else if (retrievedArray && values?.Comment && values?.Document) {
                setUploadInfoArray([
                    ...retrievedArray,
                    newDocument
                ]);
                const mergedArray = [
                    ...retrievedArray,
                    newDocument
                ]
                dispatch(UploadedDocumentInfo(mergedArray))
                navigate("/view-document")
            }
        } catch (error) {
            toast.error(error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const renderInputs = [
        { type: 'file', name: 'Document', label: "Attach A File" },
        { type: "textarea", name: "Comment", placeholder: "Add Comment Here" }
    ];

    return (
        <Container>
            <section className="mt-4">
                {loading ? (
                    <div className={`custom-loader ${loading ? 'show' : 'hide'}`}>
                        <ChangingProgressProvider values={[0, 20, 40, 60, 80, 100]}>
                            {percentage => (
                                <CircularProgressbar value={percentage} text={`${percentage}%`}
                                    styles={{
                                        path: {
                                            stroke: "#ffff",
                                            transformOrigin: "center center",
                                            strokeLinecap: "butt"
                                        },
                                        trail: {
                                            stroke: "black",
                                            strokeWidth: 4,
                                        },
                                        text: {
                                            fill: "#ffff",
                                        },
                                        root: {
                                            width: "51%",
                                            height: "50%",
                                        },
                                    }}
                                />
                            )}
                        </ChangingProgressProvider>
                    </div>
                ) : (
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-10">
                            <div className="profile-image-container text-center">
                                <div className="profile-image-container-inner">
                                    <ProfileImage displayLogo={selectedSideNavOption?.menu_icon || "assets/img/avatar/default-user.svg"} />
                                    <Link to="/view-document" className="view-document-link" >View Your Documents <FaChevronRight /></Link>
                                </div>
                            </div>
                            <h5 className="mt-4 mb-4 text-uppercase block-title text-center">{selectedCategoryOption?.category_name || "No Category Found"}</h5>
                            <CommonFormikForm
                                initialValues={initialValues}
                                validationSchema={fileUploadValidationSchema}
                                onSubmit={handleSubmit}
                                renderInputs={renderInputs}
                                isDisplayEditIcon={true}
                                submitBtnClass="w-100"
                            />
                        </div>
                    </div>
                )}
            </section>
        </Container>

    );
};

export default UploadDocument;