import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function AppModal({
    children,
    show,
    onClose,
    heading = '',
    subHeading = '',
    headerClass = '',
    headingClass = '',
    bodyClass = '',
    centered = true,
    hideClose = false,
    closeLabel = 'Close',
    contentClassName = '',
    size = '',
    footerContent,
    ...rest
}) {
    return (
        <Modal show={show} onHide={onClose} centered={centered} {...rest} contentClassName={contentClassName} size={size}>
            <Modal.Header className={`${headerClass}`}>
                {heading && (
                    <Modal.Title as="h3" className={`${headingClass}`}>
                        {heading}
                    </Modal.Title>
                )}
                {!hideClose && (
                    <Button
                        variant="link"
                        className="close"
                        onClick={onClose}
                        aria-label={closeLabel}
                    >

                        <img src='/assets/img/icons/modal-close.png ' alt='close ' />
                    </Button>
                )}

                {subHeading && <h6>{subHeading}</h6>}
            </Modal.Header>

            <Modal.Body className={`${bodyClass}`}>{children}</Modal.Body>

        </Modal>
    );
}

export default AppModal;
