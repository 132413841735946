import React from "react";
import { Table, Button } from "react-bootstrap";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Link } from "react-router-dom";
const CommonTable = (props) => {
    const {
        header,
        headerBackgroundColor,
        headerFontColor,
        tableTitle,
        rows,
        columns,
        tableClassName,
        columnName,
        totalCount,
        loadMore,
        setPage,
        page,
        pageSize,
        pagination,
        notFoundMessage,
        handleDelete
    } = props;
    const handlePrevious = () => {
        if (page > 1) {
            setPage((prevPage) => prevPage - 1);
        }
    };

    const handleNext = () => {
        console.log(totalCount, "totalCount");
        const maxPage = Math.ceil(totalCount / pageSize);
        if (page < maxPage) {
            setPage((prevPage) => prevPage + 1);
        }
    };
    const openFileInNewTab = (file) => {
        console.log(file, "filefilefile")
        const fileBlob = new Blob([file], { type: file.type });
        const fileUrl = URL.createObjectURL(fileBlob);
        console.log(fileUrl, "fileUrl")
        window.open(fileUrl, '_blank');
    };
    console.log(rows,"rows",notFoundMessage)
    const renderTableData = () => {
        return rows.map((row, rowIndex) => {
            return (
                <tr key={rowIndex}>
                    {columnName.map((column, cellIndex) => {
                        console.log(cellIndex, "tabledata", row)
                        console.log(row[column], "column", row[column]?.name)
                        console.log(column == 'document_url' && row.document_url, "checkingggggg", column, row?.document_url)
                        const className = `column-text ${column.toLowerCase().replace(/\s+/g, '_')}`;
                        const name = row[column]
                        console.log(name, "rowIndex", rowIndex)
                        return (
                            <td key={cellIndex} className={className}>
                                {cellIndex === 0 && (
                                    <div>
                                        {/* Delete icon */}
                                        {rowIndex + 1}
                                    </div>
                                )}
                                {cellIndex === 3 && (
                                    <div>
                                        {row[column]}
                                        {/* Delete icon */}
                                        <RiDeleteBin6Line
                                            className="ml-2 delete-icon"
                                            onClick={() => handleDelete(row?.id, Object?.keys(row)[3])}
                                        />
                                    </div>
                                )}
                                {cellIndex !== 3 && column === 'document' && row.document && (
                                    <Link to={row?.document?.name} target="_blank" onClick={() => openFileInNewTab(row[column])}>
                                        {row[column]?.name}
                                    </Link>
                                )}
                                {cellIndex !== 3 && column !== 'document' && (
                                    row[column]
                                )}
                            </td>
                        )
                    }
                    )}
                </tr>
            );
        });
    };
    const currentList = rows && rows.length;
    console.log("currentList", currentList, totalCount);
    return rows && Array?.isArray(rows) && rows?.length ? (
        <>
            {tableTitle && <h2>{tableTitle}</h2>}
            <Table striped bordered hover responsive className={tableClassName} >
                {header && (
                    <thead>
                        <tr>
                            {columns?.map((column, index) => {
                                const className = `column-text ${column?.toLowerCase().replace(/\s+/g, '_')}`;
                                return (
                                    <th key={index} className={className}>{column}</th>
                                )
                            })}
                        </tr>
                    </thead>
                )}
                <tbody>{rows && rows.length !== 0 && renderTableData()}</tbody>
            </Table>
            {
                pagination &&
                <div className="d-flex justify-content-center align-items-center my-5 pb-14">
                    <Button
                        variant="dark"
                        size="sm"
                        onClick={handlePrevious}
                        className="btn btn-primary py-3 px-4 mr-5"
                        disabled={page === 1}
                    >
                        Prev
                    </Button>
                    <Button
                        variant="dark"
                        size="sm"
                        onClick={handleNext}
                        disabled={page === Math.ceil(totalCount / pageSize)}
                        className="btn btn-primary py-3 px-4"
                    >
                        Next
                    </Button>
                </div>
            }
        </>
    ) : (
        <div className="no-data-found">
            <span>{notFoundMessage}</span>
        </div>
    );
};

export default CommonTable;
