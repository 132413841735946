import React, { useEffect, useState } from 'react';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { FaRegUser } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { logoutUser } from '../../store/slices/auth-slice';
import { getSideBarMenuData } from '../../services/list-service';
import { SideNavChoice, UploadGeneralComment, UploadedDocumentInfo } from '../../store/slices/common-slice';
import { logout } from '../../services/auth-service';
import toast from 'react-hot-toast';
import { FaSignOutAlt } from "react-icons/fa";
import { AiOutlineMenu } from "react-icons/ai";
import { Alert } from 'react-bootstrap';
import { RiDeleteBin5Line } from "react-icons/ri";
const SideBarLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation()
  const isDashBoard = location?.pathname === '/user-dashboard'
  console.log(isDashBoard, "isDashBoard", location)
  const [collapsed, setCollapsed] = useState(false);
  const [sideBarMenuList, setSiderBarMenuList] = useState([])
  const [toggled, setToggled] = useState(false);
  const userDetails = useSelector((state) => state?.auth?.userDetails?.user)

  const firstName = userDetails && userDetails.length > 0 ? userDetails[0].firstname : "";
  const lastName = userDetails && userDetails.length > 0 ? userDetails[0].lastname : "";
  const initials = firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();

  const logoutUserAction = async () => {
    localStorage.clear();
    dispatch(logoutUser());
    navigate('/');
  };
  
  const getSideBarMenu = async () => {
    // toggleLoader(true);
    try {
      let reqData = { languageId: 1 };
      const res = await getSideBarMenuData(reqData);
      if (res.status === 200) {
        console.log("contest data response: ", res.data.data.data);
        let menuData = res.data && res.data.data;
        setSiderBarMenuList(menuData);
        const setSideBarMenu = menuData && menuData[0]
        dispatch(SideNavChoice(setSideBarMenu))
        // const selectedOption = menuData && menuData && Array?.isArray(menuData) && menuData[0]
        // dispatch(SideNavChoice(selectedOption));
        // if (menuData?.length > 0) {
        //   menuData?.map((data, i) => {
        //      const addMenu =  {
        //         userDetails: userDetails && userDetails?.userId,
        //         [menuData?.menu_name]: []
        //       }
        //     console.log(addMenu,"addMenu")
        //   })

        // }
        //   if (menuData?.length > 0) {
        //   const resultObject = {
        //     userDetails: userDetails && userDetails.userId,
        //   };

        //   // Dynamically create array properties based on menu_names
        //   menuData.forEach(item => {
        //     resultObject[item.menu_name] = [];
        //   });
        //   dispatch(UploadedDocumentInfo(resultObject))
        //   console.log(resultObject ,"resultObject")
        // }
      }
    } catch (err) {
    } finally {
      // toggleLoader(false);
    }
  };
  // useEffect(() => {
  //   getSideBarMenu()
  // }, [])

  const handleMenuSelection = (value) => {
    dispatch(SideNavChoice(value))
    setToggled(false)
    navigate("/")
  }
  console.log(sideBarMenuList, "sideBarMenuList")

  return (
    <div>
      <Sidebar
        collapsed={collapsed}
        width="300px"
        //  className={`custom-sidebar ${isDashBoard ? "d-none" : "isDashBoardRoute"}`}
        className="custom-sidebar"
        onBackdropClick={() => setToggled(false)} toggled={toggled} breakPoint="always"
      >
        <div className="sidebar-close-icon" onClick={() => setToggled(false)}>
          <AiOutlineClose />
        </div>
        <div className="sidebar-user-info">
          <span className="user-avatar">        <span className="user-avatar">
            <img
              src={`https://ui-avatars.com/api/?name=${firstName}+${lastName}&rounded=true&size=128`}
              alt="User Avatar"
              title={`${firstName} ${lastName}`}
            />
            <div className="initials">{initials}</div>
          </span></span>
          <h4>Welcome</h4>
          <h5>{userDetails && userDetails?.firstName}</h5>
          {/* <h5> {userDetails && userDetails?.clientId && `Client No -${userDetails?.clientId}` || "Client Id Not Found!"}</h5> */}
        </div>
        <Menu>
          {
            // sideBarMenuList && Array.isArray(sideBarMenuList) && sideBarMenuList.length > 0
            //   ? sideBarMenuList?.map((el, i) => {
            //     let menu_name = el && el?.menu_name;

            //     let formatted_menu_name = menu_name.replace(/_/g, ' ');
            //     return (
            //       <><MenuItem key={i} icon={el?.menu_icon ? <img src={el?.menu_icon ? el?.menu_icon : ""} className="selected-avatar" alt="user-avater" /> : <FaRegUser />} onClick={() => handleMenuSelection(el)}>
            //         {formatted_menu_name}
            //       </MenuItem></>
            //     )
            //   }
            //   )
            //   : <Alert key={'danger'} variant={'danger'} className="text-center mx-3">No menu found</Alert>
            userDetails && Array.isArray(userDetails) && userDetails.map((el, index) => {
              return (
                <MenuItem key={index} icon={el?.menu_icon ? <img src={el?.menu_icon ? el?.menu_icon : ""} className="selected-avatar" alt="user-avater" /> : <FaRegUser />} onClick={() => handleMenuSelection(el)}>
                  {el.firstname + " " + el.lastname}
                </MenuItem>
              )
            })
          }
          <div className="separator-line">&nbsp;</div>
          <MenuItem icon={<FaSignOutAlt />} onClick={logoutUserAction}> Sign Out</MenuItem>
          <MenuItem icon={<RiDeleteBin5Line />}> Delete Account</MenuItem>
        </Menu>
      </Sidebar>
      {
        isDashBoard &&
        <div className="sidebar-toggle-icon" onClick={() => setToggled(!toggled)}>
          <AiOutlineMenu />
        </div>
      }
    </div>
  )
}

export default SideBarLayout