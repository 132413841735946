import React from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { FaChevronLeft } from "react-icons/fa";

const ProfileImage = (props) => {
    const { displayLogo } = props
    const { pathname } = useLocation();
    const userDetails = useSelector((state) => (state?.auth?.userDetails?.user));
    const selectedSideNavOption = useSelector((state) => (state?.common?.selectedSideNavOption))
    const goBack = () => {
        window.history.back();
    }
    const firstName = userDetails && userDetails.length > 0 ? userDetails[0].firstname : "";
    const lastName = userDetails && userDetails.length > 0 ? userDetails[0].lastname : "";
    const initials = firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
    
    return (
        <div className="profile-image-box">
            {pathname !== "/user-dashboard" &&
                <Button
                    variant="link"
                    onClick={() => goBack()}
                    className="back-btn"
                >
                    <FaChevronLeft /> Back
                </Button>
            }
            <div className="profile-image-content">
                <span className="user-avatar">
                    <img
                        src={`https://ui-avatars.com/api/?name=${firstName}+${lastName}&rounded=true&size=128`}
                        alt="User Avatar"
                        title={`${firstName} ${lastName}`}
                    />
                    <div className="initials">{initials}</div>
                </span>

                <h6 className="title">
                    {selectedSideNavOption && selectedSideNavOption?.id && "Welcome"} &nbsp;
                    {firstName}</h6>
            </div>
        </div>
    )
}

export default ProfileImage;