import { AxiosInstance } from '../utils/axios-helper/axios-helper';
import { API_END_POINT } from '../utils/constants/api-end-points';

export const uploadDocument = async (formData) => {
    return await AxiosInstance.post(
        `${API_END_POINT.uploadDocument}`, formData
    );
};

export const uploadComment = async (formData) => {
    return await AxiosInstance.post(
        `${API_END_POINT.addGeneralComments}`, formData
    );
};