import React, { useEffect, useState } from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { Modal, Button, Alert, Stack } from 'react-bootstrap';
import ProfileImage from '../components/common/ProfileImage';
import CommonTable from '../components/common/Table';
import { getAllDocumentCommentList, getAllGeneralCommentList } from '../services/list-service';
import { useDispatch, useSelector } from 'react-redux';
import { deleteComment, deleteDocument } from '../services/delete-service';
import AppModal from '../components/modals';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { removeComments, removeDocument } from '../store/slices/common-slice';
import ChangingProgressProvider from '../components/loader/ChangingProgressProvider';
import { CircularProgressbar } from 'react-circular-progressbar';
import { uploadDocument } from '../services/add-service';
import { GrDocumentText } from "react-icons/gr";
import toast from 'react-hot-toast';
const ViewDocument = () => {
    const navigate = useNavigate()
    const [generalCommentList, setGeneralCommentList] = useState([]);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [deleteType, setDeleteType] = useState('');
    const [tableData, setTableData] = useState([])
    const [commentData, setCommentData] = useState([])
    const dispatch = useDispatch()
    const header = true;
    const headerBackgroundColor = 'black';
    const headerFontColor = 'white';

    //document column
    const documentColumn = ["S NO.", 'Document', "Comment", 'Action',];
    const documentColumnName = ["", 'id', 'comments', 'action'];
    //comment column
    const commentColumn = ["S NO.", "", 'Comments', 'Action'];
    const commentColumnName = ["", 'id', 'comment', 'action'];
    const tableClassName = 'custom-table mb-0';
    const userDetails = useSelector((state) => state?.auth?.userDetails);
    const retriveUploadedDocument = useSelector((state) => state?.common?.uploadedDocumentInfo);
    const retriveGeneralComment = useSelector((state) => state?.common?.uploadedGeneralComment);
    const selectedSideNavOption = useSelector((state) => state?.common?.selectedSideNavOption);
    const selectedCategoryOption = useSelector((state) => state?.common?.selectedCategoryOption);
    const selectedYearOption = useSelector((state) => state?.common?.selectedYearOption);
    console.log(retriveUploadedDocument, "retriveUploadedDocument")
    console.log(tableData, "tableDatatableData")
    const handleDelete = (ids, isCommentOrDocument) => {
        console.log(ids, "idssssssssssss", isCommentOrDocument)
        console.log(isCommentOrDocument, "isCommentOrDocument")
        if (isCommentOrDocument === "comment") {
            const commentIds = ids
            dispatch(removeComments({ commentIds }));
        } else {
            dispatch(removeDocument({ ids }));
        }
        // 
    };
    //show upload listing
    useEffect(() => {
        if (retriveUploadedDocument && retriveUploadedDocument?.length > 0) {
            const filteredDocumentArray = retriveUploadedDocument?.filter(item =>
                // item.staffid === userDetails?.staffid && 
                item.selectedSideNavOption === selectedSideNavOption?.staffid &&
                item.selectedYearOption === selectedYearOption
            );
            setTableData(filteredDocumentArray)
            console.log(filteredDocumentArray, "filteredDocumentArray")

        } else {
            setTableData([])
        }
    }, [retriveUploadedDocument, selectedSideNavOption, selectedCategoryOption, selectedYearOption, userDetails])

    //show comment listing
    useEffect(() => {
        if (retriveGeneralComment && retriveGeneralComment?.length > 0) {
            const filteredCommentArray = retriveGeneralComment?.filter(item =>
                // item.userId === userDetails?.userId &&
                item.selectedSideNavOption === selectedSideNavOption?.staffid &&
                item.year === selectedYearOption
            );
            setCommentData(filteredCommentArray)
            console.log(filteredCommentArray, "filteredDocumentArray")
        } else {
            setCommentData([])
        }

    }, [retriveGeneralComment, selectedSideNavOption, selectedCategoryOption, selectedYearOption, userDetails])
    console.log(commentData, "commentDatacommentData")
    const createZipFile = async () => {
        setLoading(true)
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        // Step 2: Store all files in an array
        const files = [];
        if (tableData) {

            tableData.forEach((fileData, index) => {
                console.log(fileData?.document?.name, "fileDatafileDatafileData");
                // Assuming each file in tableData is an object with a 'dataURL' property
                const dataURL = fileData.document;
                const name = `file_${index + 1}`; // Use the original file name if available
                const contentType = dataURL.split(';')[0].split(':')[1]; // Extract MIME type
                const base64Data = dataURL.split(',')[1]; // Extract base64 data
                files.push({
                    name: name,
                    dataURL: base64Data,
                    contentType: contentType // Store MIME type for each file
                });
            });
        }

        // Step 3: Create a zip file containing all the files
        const zip = new JSZip();
        console.log(files, "filesfilesfilesfiles")

        if (commentData?.length > 0) {
            const commentsText = commentData.map(item => `${item.comment}`).join('\n');
            zip.file('comments.txt', commentsText);
        }
        files?.forEach(file => {
            const fileExtension = getFileExtension(file.contentType);
            const fileNameWithExtension = `${file.name}.${fileExtension}`;
            zip.file(fileNameWithExtension, file.dataURL, { base64: true });
        });

        // Step 4: Generate the zip file
        const zipBlob = await zip.generateAsync({ type: 'blob' });

        // Step 5: Create a FormData object and append the zip blob
        const formData = new FormData();
        formData.append('user_id', selectedSideNavOption && selectedSideNavOption?.staffid);
        formData.append('category_id', selectedCategoryOption && selectedCategoryOption?.id);
        formData.append('uploadfile', zipBlob, 'selectedFiles.zip'); // Append the zip blob with the filename
        formData.append('year', currentYear);
        await new Promise(resolve => setTimeout(resolve, 6000));
        // Step 6: Dispatch the API call
        try {
            const res = await uploadDocument(formData);
            if (res && res?.status === 200) {
                toast.success(res.data.message)
                // navigate("/upload-document")
                const ids = tableData && tableData?.map(item => item.id);
                const commentIds = commentData && commentData?.map(item => item.id);
                if (ids && commentIds) {
                    dispatch(removeDocument({ ids }));
                    dispatch(removeComments({ commentIds }));
                }
            }
            console.log(res, "resresresres")
        } catch (error) {
            setLoading(false)
            console.log(error)
        } finally {
            setLoading(false)
        }
    };

    function getFileExtension(contentType) {
        switch (contentType) {
            case 'application/pdf':
                return 'pdf';
            case 'image/jpeg':
            case 'image/jpg':
                return 'jpg';
            case 'image/png':
                return 'png';
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                return 'xlsx';
            default:
                if (!contentType.startsWith('video/')) {
                    return 'txt'; // Default extension if content type is not a video
                }
                return null; // Return null for video content types
        }
    }
    const handleShowConfirmationModal = (id, type) => {
        console.log(id, "ididididid", type)
        setDeleteId(id);
        setDeleteType(type);
        setShowConfirmationModal(true);
    };

    const handleHideConfirmationModal = () => {
        setShowConfirmationModal(false);
    };

    const handleDeleteConfirmed = () => {
        handleHideConfirmationModal();
        handleDelete(deleteId, deleteType);
    };
    const categorizedData = {};
    if (tableData.length > 0) {
        tableData?.forEach(item => {
            const categoryName = item.selectedCategoryName;
            categorizedData[categoryName] = categorizedData[categoryName] || [];
            categorizedData[categoryName].push(item);
        });
    }
    return (
        <Container>
            <section className="my-4">
                {
                    loading ? (

                        <div className={`custom-loader ${loading ? 'show' : 'hide'}`}>
                            <ChangingProgressProvider values={[0, 20, 40, 60, 80, 100]}>
                                {percentage => (
                                    <CircularProgressbar value={percentage} text={`${percentage}%`}
                                        styles={{
                                            path: {
                                                stroke: "#ffff",
                                                transformOrigin: "center center",
                                                strokeLinecap: "butt"
                                            },
                                            trail: {
                                                stroke: "black",
                                                strokeWidth: 4,
                                            },
                                            text: {
                                                fill: "#ffff",
                                            },
                                            root: {
                                                width: "51%",
                                                height: "50%",
                                            },
                                        }}
                                    />
                                )}
                            </ChangingProgressProvider>
                        </div>
                    ) : (
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-10">
                                <div className="profile-image-container text-center">
                                    <div className="profile-image-container-inner">
                                        <ProfileImage
                                            displayLogo={selectedSideNavOption?.menu_icon ? selectedSideNavOption?.menu_icon : "assets/img/avatar/default-user.svg"}
                                        />
                                    </div>
                                </div>

                                <div className="view-document-block">
                                    {tableData && Object.keys(categorizedData).length > 0 ? (
                                        Object.keys(categorizedData).map(categoryName => (
                                            <div key={categoryName}>
                                                <h5 className="mb-3 text-uppercase block-title">{categoryName}</h5>
                                                <CommonTable
                                                    header={header}
                                                    headerBackgroundColor={headerBackgroundColor}
                                                    headerFontColor={headerFontColor}
                                                    rows={categorizedData[categoryName]}
                                                    columns={documentColumn}
                                                    tableClassName={tableClassName}
                                                    columnName={documentColumnName}
                                                    pagination={false}
                                                    notFoundMessage={"No Document Found !!!"}
                                                    handleDelete={handleShowConfirmationModal}
                                                />
                                            </div>
                                        ))
                                    ) : (
                                        <div className="no-data-found">
                                            <span>{"No Document Found !!!"}</span>
                                        </div>
                                    )}
                                </div>


                                <div className="view-document-block">
                                    <h5 className="mb-3 text-uppercase block-title">General Comments</h5>
                                    <CommonTable
                                        header={header}
                                        headerBackgroundColor={headerBackgroundColor}
                                        headerFontColor={headerFontColor}
                                        rows={commentData}
                                        columns={commentColumn}
                                        tableClassName={tableClassName}
                                        columnName={commentColumnName}
                                        pagination={false}
                                        notFoundMessage="No Comment Found !!!"
                                        handleDelete={handleShowConfirmationModal}
                                    />
                                </div>
                                <Stack className="mx-auto text-center mt-4">
                                    <Button variant="primary" size="lg" onClick={() => navigate('/upload-document')}>
                                        ATTACH AN OTHER FILE
                                    </Button>

                                </Stack>
                                <Stack className="mx-auto text-center mt-4">
                                    <Button variant="primary" size="lg" type='submit' onClick={createZipFile}>
                                        SEND
                                    </Button>
                                </Stack>
                            </div>
                        </div>

                    )

                }

            </section>

            <AppModal heading='Confirmation' show={showConfirmationModal} onClose={handleHideConfirmationModal}>
                <Alert key={'danger'} variant={'danger'} className="mx-2">
                    Do you really want to Delete?
                </Alert>
                <Modal.Footer className="p-0 border-0">
                    <Button variant="danger" onClick={handleHideConfirmationModal}>
                        No
                    </Button>
                    <Button variant="primary" onClick={handleDeleteConfirmed}>
                        Yes
                    </Button>
                </Modal.Footer>
            </AppModal>
        </Container>
    );
};

export default ViewDocument;