import React from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
} from "react-router-dom";
import useAuth from "../hooks/use-auth";
import { Suspense } from "react";
import FullScreenLoader from "../../components/loader";
import LandingPage from "../../pages/LandingPage";
import UserDashBoard from "../../pages/UserDashBoard";
import UploadDocument from "../../pages/UploadDocument";
import ViewDocument from "../../pages/ViewDocument";
import Page404 from "../../pages/404";
import NavBar from "../../components/layouts/NavBarLayout";

// Create a wrapper component for lazy loaded components
function LazyRoute({ component: Component }) {
  return (
    <Suspense fallback={<FullScreenLoader />}>
      <Component />
    </Suspense>
  );
}

function ProtectedRoute({ component: Component }) {
  const { isLoggedIn } = useAuth();

  if (!isLoggedIn) {
    // Redirect to home page if not authenticated
    return <Navigate to="/" />;
  }

  return (
    <>
      <NavBar />
      <Component />
    </>
  );
}

function PublicOnlyRoute({ component: Component }) {
  const { isLoggedIn } = useAuth();

  if (isLoggedIn) {
    // Redirect to home page if authenticated
    return <Navigate to="/user-dashboard" />;
  }

  return (
    <>
      <Component />
    </>
  );
}

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        path="/"
        element={<PublicOnlyRoute component={LandingPage} />}
        errorElement={<LazyRoute component={Page404} />}
      />
      <Route
        path="/user-dashboard"
        element={<ProtectedRoute component={UserDashBoard} />}
      />
      <Route
        path="/upload-document"
        element={<ProtectedRoute component={UploadDocument} />}
      />
      <Route
        path="/view-document"
        element={<ProtectedRoute component={ViewDocument} />}
      />
    </>
  )
);
