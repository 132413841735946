import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { persistor, store } from "./store";
import { router } from "./utils/routes";
import { Toaster } from "react-hot-toast";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
      <Toaster
            position="top-center"
            reverseOrder={false}
            toastOptions={{
              className: "",
              style: {
                backgroundColor: "black", // Black background color
                color: "white", // White text color
                border: "1px solid #713200",
                padding: "10px",
              },
              duration: 3000, // Set a duration greater than 0 to remove the close button
              icon: false, // Remove success icon
            }}
          />
        <RouterProvider router={router}>
          <App />
        </RouterProvider>
 
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
