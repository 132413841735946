import React, { useState } from "react";
import LoginForm from '../components/common/form/Loginform'
import HomePageLayout from '../components/layouts/HomePageLayout'

const LandingPage = () => {
    const [showLoginForm, setShowLoginForm] = useState(false);
    const handleTimeout = () => {
        setShowLoginForm(true);
    };
    return (
        <>
            {/* {!showLoginForm ? (
                <HomePageLayout onTimeout={handleTimeout} />
            ) : (
                <LoginForm />
            )} */}
                            <LoginForm />

        </>
    )
}

export default LandingPage;