import * as yup from "yup";
import { VALIDATION_MSG } from "../constants/validation-constants";
import { REGEX } from "../constants/validation-regex";
export const loginValidationSchema = yup.object({
  Email: yup
    .string()
    .email(VALIDATION_MSG.valid["email"])
    .matches(REGEX.email, VALIDATION_MSG.valid["email"])
    .required(VALIDATION_MSG.required["email"]),
  Pin: yup
    .string()
    .min(4, `${VALIDATION_MSG.valid["pin"]}`)
    .max(4, `${VALIDATION_MSG.valid["pin"]}`)
    .required(VALIDATION_MSG.required["pin"]),
});
