import { useEffect } from "react";
import { LoaderProvider } from "./utils/context";
import { Toaster } from "react-hot-toast";
import "./styles/style.css";

function App() {
  return (
    <LoaderProvider>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          className: "",
          style: {
            backgroundColor: "black", // Black background color
            color: "white", // White text color
            border: "1px solid #713200",
            padding: "10px",
          },
          duration: 3000, // Set a duration greater than 0 to remove the close button
          icon: false, // Remove success icon
        }}
      />
    </LoaderProvider>
  );
}

export default App;
