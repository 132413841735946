import React from "react";
export default function FileUploadForm(props) {
    const { selected = '',
        setFieldValue, inputProps, values } = props;

    console.log(values, "checking", selected)
    const uploadMultipleFiles = (e) => {

        console.log(e.target.files, "e.target.files")
        const selectedFiles = e.target.files[0]
        console.log(selectedFiles, "selectedFiles out")
        // if (selectedFiles && selectedFiles !== null || undefined) {
        //     console.log(selectedFiles, "selectedFiles")
        //     setFieldValue("Document", selectedFiles)
        // } else {
        //     // Resetting the value of the file input if no file is selected
        //     e.target.value = null;
        // }

    // }
    //        if (!selectedFiles) {
    //             console.error('No file selected.');
    //             return;
    //         }
         
    //         const file = e.target.files[0];
         
    //         const reader = new FileReader()

    //         // Convert image to data URL
    //         reader.readAsDataURL(file)
        
    //         reader.addEventListener('load', () => {
    //             // Save data URL to local storage
    //             setFieldValue("Document", reader.result)
    //              localStorage.setItem('thumbnail', reader.result)
    //         })
    // };


        var reader = new FileReader();
        reader.readAsDataURL(selectedFiles);
        reader.onload = function () {
          console.log(reader.result);
          setFieldValue("Document", reader.result)
        //    localStorage.setItem('thumbnail', reader.result)
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
    }
    const resetInput = () => {
        setFieldValue("Document", null)
    }
    console.log(inputProps, "inputProps")
    return (
        <div className="w-100">

            {/* {filesSelected.length > 0 && (
                    <div className="mb-3">
                        {filesSelected.map((file, index) => (
                            <img key={index} src={URL.createObjectURL(file)} alt={`Preview-${index}`} />
                        ))}
                    </div>
                )} */}
            <div>
                <input
                    type="file"
                    className="form-control"
                     onClick={resetInput}
                    onChange={uploadMultipleFiles}
                    name={inputProps?.name}
                    accept=".jpg, .jpeg, .png, .gif, .pdf, .xls, .xlsx"
                // multiple
                />
            </div>
        </div>
    );
}
