import React from 'react';
import CategoryLayout from '../components/layouts/CategoryLayout';
import Container from 'react-bootstrap/Container';

const UserDashBoard = () => {
    return (
        <Container>
            <section className="my-4">
                <div className="row d-flex justify-content-center">
                    <div className="col-lg-10">
                        <CategoryLayout />
                    </div>
                </div>
            </section>
        </Container>
    )
}

export default UserDashBoard;