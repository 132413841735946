import { useSelector } from 'react-redux';

function useAuth() {
  const token = localStorage.getItem('token');
  const isLoggedIn = token ? true : false;
  const userDetails = useSelector((state) => state.auth.userDetails);
  return { isLoggedIn, userDetails };
}

export default useAuth;
