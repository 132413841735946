import axios from "axios";
import { API_BASE_URL } from "../../config/app-config";
import { store } from "../../store";
import { logoutUser } from "../../store/slices/auth-slice";
import { apiErrorHandler } from "./api-error-handler";

// axios instance
export const AxiosInstance = axios.create({
baseURL: `${API_BASE_URL}`,
});

// Add a request interceptor
AxiosInstance.interceptors.request.use(
  function (config) {
    // Do something before request is sent

    const Token = store.getState()?.auth?.userDetails?.token;
    console.log(Token, "Token", store.getState());
    if (Token) {
      config.headers.Authorization = `Bearer ${Token}`;
    }

    return config;
  },
  function (error) {
    console.log(error, "error");
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
AxiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    console.log(
      error,
      "error:eeee ",
      error?.response?.status,
      error?.response?.status === 401
    );
    if (error?.response?.status === 401) {
      apiErrorHandler(error);
      // logout user on 401 error
      store.dispatch(logoutUser());
      localStorage.clear();
      window.location.assign = "/";
      if (typeof window !== "undefined") {
        window.location.assign = "/";
      }
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
