import * as yup from "yup";
import { VALIDATION_MSG } from "../constants/validation-constants";
import { REGEX } from "../constants/validation-regex";

export const addGeneralCommentValidationSchema = yup.object({
    GeneralComments: yup
    .string()
    .required(VALIDATION_MSG.required["GeneralComments"]),
});

export const fileUploadValidationSchema =
yup.object({
    Document: yup.mixed().required("File is required"),
    Comment: yup
    .string()
    .required(VALIDATION_MSG.required["Comment"]),
});
