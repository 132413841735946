import { AxiosInstance } from "../utils/axios-helper/axios-helper";
import { API_END_POINT } from "../utils/constants/api-end-points";

//login
export const login = async (data) => {
    return await AxiosInstance.post(API_END_POINT.login,data);  
  };


  //logout 
  export const logout = async (data) => {
    return await AxiosInstance.post(API_END_POINT.logout,data);  
  };
