import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const YearPicker = ({ selectedYear, onYearChange, className }) => {
  const startYear = 2018; // Change this to set the starting year
  const currentYear = new Date().getFullYear();
  const selectedYearOption = useSelector((state) => (state?.common?.selectedYearOption))
  const years = Array.from({ length: currentYear - startYear + 1 }, (_, index) => startYear + index);
const defaultYearSelected = selectedYearOption ? selectedYearOption : years
  const handleYearChange = (e) => {
    const selectedYear = parseInt(e.target.value, 10);
    onYearChange(selectedYear);
  };

  return (
    <select value={defaultYearSelected} onChange={handleYearChange} className={`form-select ${className ? className : ''}`}>
      {years.map((year) => (
        <option key={year} value={year}>
          {year}
        </option>
      ))}
    </select>
  );
};

export default YearPicker;