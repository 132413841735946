import React, { useState, useEffect } from "react";
import YearPicker from "../common/YearPicker";
import ProfileImage from "../common/ProfileImage";
import Button from 'react-bootstrap/Button';
import { getCategoryMenuData } from "../../services/list-service";
import { useDispatch, useSelector } from "react-redux";
import { CategoryChoice, UploadedDocumentInfo, YearChoice } from "../../store/slices/common-slice";
import { useNavigate } from "react-router-dom";
import { AiFillDollarCircle, AiOutlineFileText, AiOutlineMedicineBox, AiFillMedicineBox } from "react-icons/ai";
import { Alert } from "react-bootstrap";

const CategoryLayout = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [startYear, setStartYear] = useState(new Date());
    const [categoryList, setCategoryList] = useState([
        {
          "id": "1",
          "category_name": "SLIPS",
          "icon": "https://picco.animainnovation.com/webservice/assets/upload/icon/SLIPS_2024-02-09_65c63bddcc3d7.png"
        },
        {
          "id": "2",
          "category_name": "BUSINESS EXPENSES AND INCOME",
          "icon": "https://picco.animainnovation.com/webservice/assets/upload/icon/BUSINESS_EXPENSES_AND_INCOME_2024-02-09_65c63c1099f7e.png"
        },
        {
          "id": "3",
          "category_name": "DONATIONS",
          "icon": "https://picco.animainnovation.com/webservice/assets/upload/icon/DONATIONS_2024-02-09_65c63c7ed72ec.png"
        },
        {
          "id": "4",
          "category_name": "MEDICAL",
          "icon": "https://picco.animainnovation.com/webservice/assets/upload/icon/MEDICAL_2024-02-09_65c63c91d73ea.png"
        },
        {
          "id": "5",
          "category_name": "MISCELLANEOUS",
          "icon": "https://picco.animainnovation.com/webservice/assets/upload/icon/MISCELLANEOUS_2024-02-09_65c63ca3ee0e9.png"
        }
      ])
    const selectedSideNavOption = useSelector((state) => (state?.common?.selectedSideNavOption))
    const selectedYearOption = useSelector((state) => (state?.common?.selectedYearOption))
    let uploadedDocumentInfo = useSelector((state) => (state?.common?.uploadedDocumentInfo))

    // useEffect(() => {
    //     if (categoryList?.length > 0 && uploadedDocumentInfo !== null || undefined) {
    //         const dynamicArrayNames = Object.keys(uploadedDocumentInfo);
    //         console.log(dynamicArrayNames, "dynamicArrayNames")
    //         for (let key of dynamicArrayNames) {
    //             let value = dynamicArrayNames[key];
    //             console.log(`Key: ${key}, Value: ${value}`);
    //             console.log(value, "uploadedDocumentInfo[key]keykeykey", uploadedDocumentInfo?.key)
    //             if (key !== undefined) {
    //                 const assign = categoryList.map(item => ({ Year: selectedYearOption, [item.category_name]: [] }));
    //               console.log(assign,"assign")
    //               uploadedDocumentInfo?.key()
    //             }
    //             // dispatch(UploadedDocumentInfo(uploadedDocumentInfo))
    //         }

    //         // dynamicArrayNames.forEach(dynamicArray => {
    //         //     let myVariableWithoutSpaces = dynamicArray.replace(/\s/g, "");

    //         //     // if (dynamicArray !== undefined && dynamicArray === "userDetails") {
    //         //     //     uploadedDocumentInfo[dynamicArray] = categoryList.map(item => ({ Year : selectedYearOption ,[item.category_name]: [] }));
    //         //     // }
    //         //     console.log(uploadedDocumentInfo,"uploadedDocumentInfo")
    //         //     dispatch(UploadedDocumentInfo(uploadedDocumentInfo))
    //         // });
    //     }
    // }, [categoryList, uploadedDocumentInfo])

    const getCategoryList = async () => {
        // toggleLoader(true);
        try {
            let reqData = { languageId: 1 };
            const res = await getCategoryMenuData(reqData);
            if (res.status === 200) {
                console.log("data response: ", res.data.data.data);
                let categoryData = res.data && res.data.data;
                setCategoryList(categoryData);
                console.log(categoryList, "categoryList")
                const selectedOption = categoryData && categoryData && Array?.isArray(categoryData) && categoryData[0]
                dispatch(CategoryChoice(selectedOption))
            }
        } catch (err) {
        } finally {
            // toggleLoader(false);
        }
    };

    // useEffect(() => {
    //     getCategoryList()
    // }, [])
    const handleCategorySelection = (value) => {
        dispatch(CategoryChoice(value))
        navigate("/upload-document")
    }

    const handleYearChange = (year) => {
        setStartYear(year);
        dispatch(YearChoice(year))
    };

    const colorIconInfo = [
        { bgColor: '#333333', iconClass: <AiOutlineFileText /> },
        { bgColor: '#55acee', iconClass: <AiFillMedicineBox /> },
        { bgColor: '#c61118', iconClass: <AiFillDollarCircle /> },
        { bgColor: '#ec4a89', iconClass: <AiOutlineMedicineBox /> },
    ];

    return (
        <div>
            <div className="profile-image-container text-center">
                <div className="profile-image-container-inner">
                    <ProfileImage
                        displayLogo={selectedSideNavOption?.menu_icon ? selectedSideNavOption?.menu_icon : "assets/img/avatar/default-user.svg"}
                    />
                    {/* <h6 className='mt-3'>{selectedSideNavOption?.menu_name ? selectedSideNavOption?.menu_name : selectedSideNavOption}</h6> */}
                    <YearPicker
                        startYear={startYear}
                        setStartYear={setStartYear}
                        onYearChange={handleYearChange}
                        className={'year-picker'}
                    />
                </div>
            </div>
            <h5 className="mt-5 mb-4 text-uppercase block-title text-center">
                Documents
            </h5>
            <div className="pb-5 d-grid gap-4">
                {
                    categoryList && Array.isArray(categoryList) && categoryList.length > 0
                        ? categoryList.map((el, i) => {
                            let category_name = el && el?.category_name;

                            let formatted_category_name = category_name.replace(/_/g, ' ');
                            return (

                                <Button
                                    variant="outline-primary"
                                    size="lg"
                                    key={i}
                                    className="document-category-btn"
                                    data-mdb-ripple-init
                                    // style={{ backgroundColor: colorIconInfo[i % colorIconInfo.length].bgColor }}
                                    role="button"
                                    onClick={() => handleCategorySelection(el)}
                                    icon
                                >
                                    <span>{formatted_category_name || "Not Found"}</span>
                                    <span className="btn-icon">
                                        <img src={el?.icon} alt="category_icon" />
                                    </span>
                                </Button>
                            )
                        })
                        : <Alert key={'danger'} variant={'danger'} className="text-center">No menu found</Alert>
                }

            </div>
        </div>
    );
}

export default CategoryLayout;