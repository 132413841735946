import React from "react";
import { PropagateLoader } from "react-spinners";

function FullScreenLoader({ isLoading }) {

  if (isLoading) {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: "100vw",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(255, 255, 255, 0.2)",
          zIndex: 999999,
        }}
      >
        <PropagateLoader color={"#f66f01"} width={"7px"} loading={true} />
      </div>
    );
  }

  return null;
}

export default FullScreenLoader;
