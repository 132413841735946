import React from 'react';
import { Button } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import FileUploadForm from './FileUploadForm';

const CommonFormikForm = ({
  initialValues,
  validationSchema,
  onSubmit,
  onCancel,
  isCancelBtnVisible = false,
  footerClass,
  renderInputs,
  profileState,
  formClass,
  submitBtnClass,
  ButtonText = 'SUBMIT',

}) => {
  console.log(renderInputs,"renderInputs")
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}>
      {({
        handleSubmit,
        handleChange,
        values,
        errors,
        setFieldValue,
        touched,
        getFieldProps,
        setFieldError,
      }) => (
        <Form autoComplete="off" className={formClass}>
          {renderInputs && Array?.isArray(renderInputs) && renderInputs?.map((inputProps, index) => (
            <div
              key={index}
              className={`form-group mb-4 ${inputProps.formGroupClass || ''} ${inputProps.type === 'image' ? 'position-relative' : ''
                }`}>
              {inputProps.label && (
                <label className="form-label" htmlFor={inputProps.name}>{inputProps.label}:</label>
              )}
              {console.log('errors', errors)}
              {inputProps.type === 'text' && (
                <Field
                  type={inputProps.type}
                  name={inputProps.name}
                  className={`form-control form-outline ${touched[inputProps.name] && errors[inputProps.name]
                    ? 'is-invalid'
                    : ''
                    }`}
                  placeholder={inputProps.placeholder}
                  disabled={inputProps.disabled ? true : false}
                //onBlur={() => inputProps.action(values[inputProps.name], setFieldError)}
                />
              )}
              {inputProps.type === 'textarea' && (
                <Field
                  as={inputProps.type}
                  type={inputProps.type}
                  name={inputProps.name}
                  className={`form-control form-outline ${touched[inputProps.name] && errors[inputProps.name]
                    ? 'is-invalid'
                    : ''
                    }`}
                  placeholder={inputProps.placeholder}
                  disabled={inputProps.disabled ? true : false}
                  rows="4"
                //onBlur={() => inputProps.action(values[inputProps.name], setFieldError)}
                />
              )}
              {inputProps.type === 'email' && (
                <Field
                  type={inputProps.type}
                  name={inputProps.name}
                  className={`form-control form-outline mb-4 ${touched[inputProps.name] && errors[inputProps.name]
                    ? 'is-invalid'
                    : ''
                    }`}
                  placeholder={inputProps.placeholder}
                  disabled={inputProps.disabled ? true : false}
                />
              )}
              {inputProps.type === 'number' && (
                <Field
                  type="number"
                  name={inputProps.name}
                  className={`form-control  form-outline mb-4${touched[inputProps.name] && errors[inputProps.name]
                    ? 'is-invalid'
                    : ''
                    }`}
                  placeholder={inputProps.placeholder}
                  disabled={inputProps.disabled ? true : false}
                />
              )}
              {inputProps.type === 'password' && (
                <Field
                  type="password"
                  name={inputProps.name}
                  className={`form-control form-outline ${touched[inputProps.name] && errors[inputProps.name]
                    ? 'is-invalid'
                    : ''
                    }`}
                  placeholder={inputProps.placeholder}
                  disabled={inputProps.disabled ? true : false}
                />
              )}
              {inputProps.type === 'date' && (
                <Field
                  type={inputProps.type}
                  name={inputProps.name}
                  className={`form-control form-date ${touched[inputProps.name] && errors[inputProps.name]
                    ? 'is-invalid'
                    : ''
                    }`}
                  placeholder={inputProps.placeholder}
                  disabled={inputProps.disabled ? true : false}
                />
              )}
              {inputProps.type === 'select' && (
                <Field
                  as="select"
                  name={inputProps.name}
                  className={`form-select form-custom-select ${touched[inputProps.name] && errors[inputProps.name]
                    ? 'is-invalid'
                    : ''
                    }`}
                  placeholder={inputProps.placeholder}
                  value={values[inputProps.name]}
                  disabled={inputProps.disabled ? true : false}>
                  <option value="">{inputProps.placeholder}</option>
                  {inputProps.options.map((el, optionIndex) => (
                    <option key={optionIndex} value={el.value}>
                      {el.label}
                    </option>
                  ))}
                </Field>
              )}


              {inputProps.type === 'checkbox' && (
                <>
                  <div className="d-flex">
                    <Field
                      id="tnc"
                      type="checkbox"
                      name={inputProps.name}
                      className="form-group form-check "
                      placeholder={inputProps.placeholder}
                      checked={inputProps.checked}
                      onChange={(e) => inputProps.onChange(e)}
                    />
                    <label
                      className="form-label p-1"
                      htmlFor="tnc"
                    >{inputProps?.title}</label>
                  </div>
                </>
              )}

              {inputProps.type === 'file' && (
                <FileUploadForm
                  selected={values?.Document}
                  setFieldValue={setFieldValue}
                  error={values?.Document && profileState?.error?.message}
                  values={values}
                  inputProps={inputProps}
                />
              )}

              <ErrorMessage
                name={inputProps.name}
                component="div"
                className={
                  touched[inputProps.name] && errors[inputProps.name]
                    ? 'text-danger mb-4 mt-1'
                    : 'text-danger mb-4 mt-1'
                }
              />
            </div>
          ))}
          {/* {errors.Message && (
            <div className={'text-danger'}>{errors.Message}</div>
          )} */}

          <div className={`form-footer ${footerClass ? footerClass : ''}`}>
            {isCancelBtnVisible && (
              <Button
                type="button"
                variant="danger"
                onClick={onCancel}
              >
                CANCEL
              </Button>
            )}
            <Button
              type="submit"
              variant="primary"
              className={`btn-block ${submitBtnClass}`}
            >
              {ButtonText}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CommonFormikForm;
