// NotFoundPage.js

import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
  return (
    <div className="container mt-5 text-center">
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <h1 class="display-1 fw-bold">404</h1>
          <p class="fs-3"> <span class="text-danger">Opps!</span> Page not found.</p>
          <p className="lead">The page you are looking for might be in another castle.</p>
          <img src='./assets/img/404.png' alt="404" />
          <p className="mt-4">
            <Link to="/" className="btn btn-primary">
              Go Home
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
